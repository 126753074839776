body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@font-face {
  font-family: "Athiti";
  font-style: lighter;
  font-weight: 200;
  src: local("Athiti Light"), local("Athiti-Light"),
    url("./assets/fonts/Athiti-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Athiti";
  font-style: normal;
  font-weight: 400;
  src: local("Athiti Medium"), local("Athiti-Medium"),
    url("./assets/fonts/Athiti-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Athiti";
  font-style: bold;
  font-weight: 800;
  src: local("Athiti Bold"), local("Athiti Bold"),
    url("./assets/fonts/Athiti-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "GothamRounded";
  font-style: lighter;
  font-weight: 200;
  src: local("GothamRounded Light"), local("GothamRounded-Light"),
    url("./assets/fonts/GothamRounded-Light.otf") format("truetype");
}
@font-face {
  font-family: "GothamRounded";
  font-style: normal;
  font-weight: 400;
  src: local("GothamRounded Medium"), local("GothamRounded-Medium"),
    url("./assets/fonts/GothamRounded-Medium.otf") format("truetype");
}

@font-face {
  font-family: "GothamRounded";
  font-style: bold;
  font-weight: 800;
  src: local("GothamRounded Bold"), local("GothamRounded Bold"),
    url("./assets/fonts/GothamRounded-Bold.otf") format("truetype");
}

div,
button,
label,
input,
textarea,
select,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "GothamRounded", "Athiti" !important;
  box-sizing: border-box;
}
